require('jquery-ui-bundle')
// require('lightbox2')

$(document).ready(function() {
  if($(window).width() > 780) {    
    $('#chapters').css('height', $('#audio-slideshow-container').height() - 55)
  }
  if($('.essayContainer').length) {
    $('#audio-slideshow').audioSlideshow();
  }
});
