import React, {useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';

const Tinymce = props => {

  const [content, setContent] = useState(props.content)

  return <div style={{width: '79%', marginLeft: '20%'}}>
    <input type="hidden" name={props.name} value={content} />
    <Editor
     apiKey="akkadfn2r13cdt4d89n2v9o78m2wgdl383vkon4h8v2wkfvg"
     initialValue={props.content}
     init={{
       height: 200,
       menubar: false,
       relative_urls : false,
       plugins: [
         'image link code paste lists advlist wordcount help',

       ],
       toolbar:
         'undo redo | cut copy paste | bold italic | bullist numlist | link | code help'
     }}
     onEditorChange={(editorContent) => setContent(editorContent)}
   />
   </div>
}
export default Tinymce
